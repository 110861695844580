@media only screen and (max-width: $screen-md-max) {
  #app {
    .container {
      section#banner {
        background: url(http://wireframe.wz.cz/hlidanizakazek/banner.jpg) no-repeat scroll 60% 0;
        .text {
          padding: 65px 0 0 50px;

          h1 {
            font-size: 45px;
            line-height: 1.2;
          }

        }
      }
      #categories {
        columns: 4;
      }
    }
  }
}