@media only screen and (max-width: $screen-sm-max) {

  .input-group {
    .select-style {
      border-radius: 3px;
      border-right:1px solid #ccc;
    }
  }

  #app {
    .container {
      section#banner {
        .text {
          padding: 55px 0 0 50px;

          h1 {
            font-size: 35px;
            line-height: 1.2;
          }

        }
      }

      #categories {
        columns: 3;
      }
    }
  }
}