// Variables
@import "variables";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";
@import "node_modules/fancybox/dist/scss/jquery.fancybox";

$red-color: #d82c1a;

body {
  font-family: "Open Sans", "proxima-nova", "proxima nova", "helvetica neue", "helvetica", "arial", sans-serif;
}

.select-style {
  position: relative;
  border: 1px solid #ccc;
  width: 260px;
  border-radius: 3px;
  overflow: hidden;
  background: #fafafa url(../images/arrow.png) no-repeat 98% 50%;

  select {
    padding: 7px 8px;
    width: 130%;
    border: none;
    box-shadow: none;
    background: transparent none;
    -webkit-appearance: none;
    font-size: 20px;
    &:before {
      content: " ";
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      background: #000;
      top: 0;
      right: 0;

      &:focus {
        outline: none;
      }
    }
  }
}

.input-group {
  .select-style {
    border-radius: 3px 0 0 3px;
    border-right: 0;
  }
}

.button {
  background-color: $red-color;
  padding: 12px 20px;
  color: #fff;
  text-decoration: none;
  font-size: 25px;
  display: inline-block;
  margin-top: 30px;
  border: 0;
  border-radius: 4px;
  border-bottom: 3px solid #972215;
  cursor: pointer;

  &:hover, &:active, &:focus, &:visited {
    color: #fff;
    text-decoration: none;
    background-color: darken($red-color, 5%);
  }

  &.button-full-width {
    display: block;
    text-align: center;
  }
}

.contract-box {
  overflow-x: hidden;
  overflow-y: hidden;
  .rest-contracts {
    display: none;
    transition: visibility 3s ease-out;
    &.show {
      display: block;
      transition: visibility 3s ease-out;
    }
  }
}

.contract-box-a {
  cursor: pointer;
}

h2 {
  font-size: 32px;
  color: #0C73B6;
  font-weight: bold;
}

#app {
  margin: 0;

  .text-black {
    color: #000;
  }
  .text-red {
    color: $red-color;
  }
  .text-white {
    color: #fff;
  }

  .grey {
    background-color: #fafafa;
    border: 0 solid #eeeeee;
    border-top-width: 1px;
    border-bottom-width: 1px;
  }
  .line {
    height: 5px;
    width: 120px;
    background-color: $red-color;
    margin: 20px auto;
  }
  .container {
    padding: 0;
    background-color: #FFF;
    border: 0 solid #eeeeee;
    border-right-width: 3px;
    border-left-width: 3px;

    .section {
      padding: 50px;
      text-align: center;
    }
    p.red {
      margin-top: 50px;
      font-size: 20px;
      span.red {
        color: #fff;
        padding: 16px 8px;
        background-color: $red-color;
        margin: 0 10px;
        font-size: 38px;
      }
    }

    header {
      color: #000000;
      background-color: white;
      padding: 34px 0;

      .logo {
        margin-left: 45px;
      }

      .phone {
        font-size: 18px;
        color: #929292;
        font-style: italic;
        font-weight: bold;
        padding-right: 40px;
        line-height: 56px;
        text-align: right;
        i {
          padding-right: 15px;
          vertical-align: middle;
        }
      }
    }
    section {
      .perex {
        font-size: 18px;
        color: #4D4C4C;
      }
    }
    section#banner {
      height: 400px;
      background: url(../images/banner.jpg) no-repeat scroll top right;
      color: #fff;

      .text {
        padding: 50px 0 0 50px;

        h1 {
          font-size: 48px;
          font-weight: bold;
          line-height: 1.4;
          span.red {
            color: #fff;
            padding: 5px 18px;
            background-color: $red-color;
          }
        }

        p {
          font-size: 19px;
          margin-top: 40px;
        }
      }
    }
    section#banner-lp {
      background: url(../images/banner-small.jpg) no-repeat scroll 0 50%;
      .select-style {
        margin-top: 40px;
        margin-left: 40px;
      }
      .categories {
        padding: 50px 0 50px 60px;
        margin: 0;
        color: white
      }
    }
    section.red-bar {
      background-color: $red-color;
      font-size: 32px;
      padding: 15px 25px;
      text-align: right;
      form {
        margin-bottom: 0;
        margin-left: 15px;
      }
      .input-group-addon {
        border: 1px solid #ab1708;
        background-color: #ab1708;
        color: #fff;
        font-size: 13px;
        .text-muted {
          color: #dcaca7;
        }
      }
    }
    #categories {
      text-align: left;
      columns: 5;
      -webkit-columns: 5;
      -moz-columns: 5;
      list-style: disc inside;
      margin-top: 40px;
      font-size: 95%;

      li {
        padding-left: 8px;
      }
    }
    footer {
      background-color: darken(#fafafa, 15%);
      border: 0 solid darken(#fafafa, 20%);
      border-top-width: 2px;
      padding: 25px 0;
      color: #333;

      .footer-bottom {
        margin: 20px 15px;
        padding: 20px 10px 0 10px;
        border-top: 1px solid darken(#fafafa, 20%);
      }

      .padding {
        padding: 0 40px;
      }
      .padding-left {
        padding-left: 40px;
      }
      small {
        font-size: 13px;
        color: lighten(#333, 20%);
      }

      a {
        color: lighten(#333, 20%);
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }

      ul.contacts {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
          margin-right: 25px;
          &:not(:first-child) {
            margin-top: 5px;
          }
          a {
            transition: all .3s;
            color: lighten(#333, 20%);
            display: block;
            &:hover {
              color: #333;
              background-color: darken(#fafafa, 25%);
              text-decoration: none;
            }
            position: relative;
            padding-right: 35px;
            line-height: 30px;
            background-color: darken(#fafafa, 20%);
            border-radius: 2px 0 0 2px;
            i.fa {
              position: absolute;
              right: 0px;
              top: 0px;
              background-color: darken(#fafafa, 30%);
              border-radius: 0 2px 2px 0;
              width: 30px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              font-size: 12px;
              color: darken(#fafafa, 45%);
              display: inline-block;
            }
          }
        }
      }

      //ul {
      //  margin: 0;
      //  padding: 0;
      //  list-style-type: none;
      //  li {
      //    color: $red-color;
      //    font-size: 15px;
      //    display: inline-block;
      //    margin-right: 25px;
      //    i {
      //      padding-right: 5px;
      //    }
      //    span {
      //      color: #000;
      //    }
      //  }
      //}
    }

    ul.logos {
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        padding: 10px 20px;
      }
    }
    .project {
      padding: 40px;
      .header {
        background-color: #F0F0F0;
        padding: 30px;
        text-align: left;
        border-bottom: 2px solid #D6D6D7;
      }
      .data {
        text-align: left;
        .line-grey {
          border-bottom: 2px solid #D6D6D7;
        }
        .date {
          text-align: center;
          background-color: #F5F5F8;
          font-weight: bold;
          padding-top: 15px;
          padding-bottom:15px;
        }
        .title {
          padding: 15px 0;
        }
        .city {
          padding: 15px 0;
          font-weight: bold;
        }

        .show-more {
          margin-top: 10px;
          padding-top: 5px;
          text-align: center;
          a {
            font-size: 13px;
            color: $red-color;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.fancybox-skin {
  background-color: #F5F5F8;
}

#inquiry-detail {
  .inquiry-content {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #D7DAE1;
  }

  .inquiry-filter {
    background-color: #efefef;
    padding: 20px;
    border: 1px solid #D7DAE1;
    border-top: 0;

    .mark {
      height: 36px;
      text-align: center;
      background-color: #cdcdcd;
      width: 36px;
      border-radius: 3px 0 0 3px;
      padding-top: 9px;
      display: block;
      color: #0c73b6;
    }

    .tip {
      height: 36px;
      line-height: 36px;
      font-size: 13px;
      font-weight:bold;
      padding: 0 10px;
      border-radius: 0 3px 3px 0;
      background-color: #d8d8d8;
      color: #8b8b8b;
      .text-muted {
        color: #acacac;
      }
    }

    .styled {
      width: 190px;
      height: 36px;
      border: 0;
      border-radius: 0;
      position: relative;
      overflow: hidden;
      background-color: #cdcdcd;
      &:before {
        display: block;
        position: absolute;
        width: 31px;
        height: 36px;
        right: 0;
        top: 0;
        background-color: #c2c2c2;
        font-family: 'FontAwesome', serif;
        content: "\f0d7";
        text-align: center;
        line-height: 36px;
      }
      select {
        background: transparent;
        position: relative;
        width: 205px;
        height: 36px;
        font-size: 14px;
        border: 0;
        padding: 5px;
        z-index: 1000;
        &:focus {
          outline: none;
        }
      }
    }

  }
}

.fancybox-inner {
  overflow-x: hidden !important;

  h3 {
    font-size: 19px;
    margin-top: 14px;
    font-weight: bold;
    color: #000;
  }
  .fancy-content {
    padding: 20px;
  }
}

.fancybox-skin {
  color: #000;
}

#inquiry-detail .inquiry-content small {
  font-size: 13px;
}

#__modal .col-md-4, #__modal .col-md-8 {
  padding-left: 5px;
  padding-right: 5px;
}

#inquiry-detail .inquiry-content i {
  padding-right: 10px;
  color: #0C73B6;
}

.line-small {
  height: 1px;
  width: 40px;
  background-color: #DA1617;
  margin: 10px 0;
}

.mt-20 {
  margin-top: 20px;
}

@import 'media_md';
@import 'media_sm';
@import 'media_xs';

