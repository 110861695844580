@media only screen and (max-width: $screen-xs-max) {
  h2 {
    font-size: 34px;
  }

  #app {
    margin: 0;

    .button {
      &.mb {
        margin-bottom: 40px;
      }
    }

    .container {
      border: 0;
      padding-left: 15px;
      padding-right: 15px;

      .section {
        padding: 10px 20px;
      }
      section {
        .perex {
          font-size:16px;
        }
      }
      header {
        padding: 15px 0;
        text-align: center;

        .logo {
          margin-left:0;
        }

        .phone {
          text-align:center;
          padding-right: 15px;
          i {
            padding-right: 15px;
            vertical-align: middle;
          }
        }
      }
      section#banner {
        height: auto;
        background: url(http://wireframe.wz.cz/hlidanizakazek/banner.jpg) no-repeat scroll bottom left;
        color: #fff;
        padding-bottom:40px;

        .text {
          padding: 0;
          text-align:center;
          h1 {
            font-size: 35px;
            line-height: 1.2;
          }

          p {
            font-size: 23px;
          }

        }
      }
      section.red-bar {
        font-size: 32px;
        padding: 15px 25px;
        text-align:center;
      }
      #categories {
        columns: 2;
        padding-left: 0;
        margin-top:10px;
        li {
          font-size:14px;
        }
      }

      .project {
        padding: 20px 0;
      }

    }
  }
}